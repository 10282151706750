import React from "react";
import {
  SEO,
  MarkdownContent,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid
          className={"custom-page-catering custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "a06725e0-24c5-4c9a-8096-ac97dc9bd475",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <ContactForm
                  className={""}
                  style={{}}
                  businessId={1313}
                  businessType={"merchant"}
                  locationId={1378}
                  subheader={"Catering Form"}
                  showLabels={true}
                  emailSubject={"Catering Form"}
                  constructMessage={createDefaultMessageFromState}
                  fields={[
                    {
                      type: "input",
                      label: "name",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Name",
                    },
                    {
                      type: "input",
                      label: "email",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Email",
                    },
                    {
                      type: "input",
                      label: "phone_number",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Phone number",
                    },
                    {
                      type: "input",
                      label: "address",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Address",
                    },
                    {
                      type: "input",
                      label: "number_of_guests",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Number Of Guests",
                    },
                    {
                      type: "select",
                      label: "delivery",
                      options: [
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                      ],
                      required: true,
                      inputType: "select",
                      placeholder: "",
                      displayLabel: "Delivery",
                    },
                    {
                      type: "input",
                      label: "event_date",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Event Date",
                    },
                    {
                      type: "input",
                      label: "start_time",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Start Time",
                    },
                    {
                      type: "input",
                      label: "end_time",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "End Time",
                    },
                    {
                      type: "textarea",
                      label: "message",
                      required: true,
                      inputType: "textarea",
                      placeholder: "Message",
                      displayLabel: "Message",
                    },
                  ]}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 22845 }) {
      title
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
